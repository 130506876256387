import React from 'react';
import TabPanel, { Item as TabPanelItem } from 'devextreme-react/tab-panel';

import {
  CardActivities,
  CardNotes,
  CardMessages,
  CardTasks,
  CardOpportunities
} from '../..';
import { CustomerUserList } from '../../library/customer-users/CustomerUser';
import { Height } from 'devextreme-react/cjs/chart';
import { useTranslation } from 'react-i18next';

export const ContactCards = ({
  customerRef,
  isLoading,
  tasks,
  activities,
  name,
  activeOpportunities,
  closedOpportunities,
  notes,
  messages,
}) => {
  const {t} = useTranslation();
  return (
    <div className='dx-card details-card'>
      <TabPanel
        showNavButtons
        focusStateEnabled={false}
        deferRendering={false}
      >
        <TabPanelItem title={t("users")}>
          <CustomerUserList  customerRef={customerRef}></CustomerUserList>
          <div style={{height: "50vh",color:"red"}}></div>
        </TabPanelItem>
        <TabPanelItem title={t("orders")}>
          <CardActivities activities={activities} isLoading={isLoading} />
        </TabPanelItem>
        <TabPanelItem title={t("notes")}>
          <CardNotes items={notes} user={name} />
        </TabPanelItem>
        <TabPanelItem title={t("messages")}>
          <CardMessages items={messages} user={name} />
        </TabPanelItem>
      </TabPanel>
    </div>
  );
};
