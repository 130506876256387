export const navigation = [
  {
    text: 'Analytics',
    icon: 'chart',
    path: '',
    items: [
      {
        text: 'Dashboard',
        path: '/analytics-dashboard',
      },
      {
        text: 'Sales Report',
        path: '/analytics-sales-report',
      },
    ],
  },
  {
    text: 'B2B',
    icon: 'user',
    path: '',
    items: [

      {
        text: 'Customers',
        path: '/b2b-customer-list',
      },

      {
        text: 'Customer Groups',
        path: '/b2b-customer-groups',
      },

    ],
  },
  {
    text: 'Exports',
    icon: 'export',
    path: '',
    items: [
      {
        text: 'ERP Exports',
        path: '/erp-exports',
      },

    ],
  },
  /*
  {
    text: 'Planning',
    icon: 'event',
    path: '',
    items: [
      {
        text: 'Task Details',
        path: '/planning-task-details',
      },
      {
        text: 'Scheduler',
        path: '/planning-scheduler'
      },
    ],
  },
  {
    text: 'Analytics',
    icon: 'chart',
    path: '',
    items: [
      {
        text: 'Dashboard',
        path: '/analytics-dashboard',
      },
      {
        text: 'Sales Report',
        path: '/analytics-sales-report',
      },
      {
        text: 'Geography',
        path: '/analytics-geography',
      },
    ],
  },
  {
    text: 'Authentication',
    icon: 'card',
    path: '',
    items: [
      {
        text: 'Sign In Form',
        path: '/sign-in-form',
      },
      {
        text: 'Sign Up Form',
        path: '/sign-up-form',
      },
      {
        text: 'Reset Password Form',
        path: '/reset-password-form',
      }
    ]
  },
  {
    text: 'Common',
    icon: 'box',
    path: '',
    items: [
      {
        text: 'User Profile',
        path: '/user-profile',
      }
    ]
  },
  */
];
