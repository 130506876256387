import React, { useState } from 'react';
import "./select.css";
import { getLanguageFromCookie, setLanguageCookie } from '../../../api/cookies'
import i18next from 'i18next';

import trFlag from "../../../assets/flags/tr.png";
import ukFlag from "../../../assets/flags/en.png";
import deFlag from "../../../assets/flags/de.png";
import frFlag from "../../../assets/flags/fr.png";
import nlFlag from "../../../assets/flags/nl.png";

import dkFlag from "../../../assets/flags/dk.png";
import saFlag from "../../../assets/flags/sa.png";
import itFlag from "../../../assets/flags/it.png";
import irFlag from "../../../assets/flags/ir.png";

export const LanguageSelect = () => {

  const countries = [
    { name: 'tr', flag: trFlag },
    { name: 'de', flag: deFlag },
    { name: 'en', flag: ukFlag },
    { name: 'nl', flag: nlFlag },
    { name: 'fr', flag: frFlag },
    { name: 'it', flag: itFlag },
    { name: 'dk', flag: dkFlag },
    { name: 'sa', flag: saFlag },
    { name: 'ir', flag: irFlag },
    // Buraya daha fazla ülke ekleyebilirsiniz
  ];

  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleButtonClick = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
    setIsDialogOpen(false);
    i18next.changeLanguage(country.name);
    setLanguageCookie(country.name);
  };

  return (
    <div>
      <div style={{width:"35px"}} onClick={handleButtonClick}>
        <img src={selectedCountry.flag} alt={selectedCountry.name} width="30px" />
      </div>
      {isDialogOpen && (
        <div className="dialog">
          {countries.map((country) => (
            <div key={country.name} onClick={() => handleCountrySelect(country)}>
              <img src={country.flag} alt={country.name} width="30px" />

            </div>
          ))}
        </div>
      )}
    </div>
  );
};