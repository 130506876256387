
import React, { useEffect, useState, useCallback } from 'react';

import './ContactPanel.scss';

import { getContact } from 'dx-template-gallery-data';
import { CustomerModel } from '../../../types/crm-contact';
import { withLoadPanel } from '../../../utils/withLoadPanel';
import { CustomerGroupPanelDetails } from './ContactPanelDetails';


const ContactPanelWithLoadPanel = withLoadPanel(CustomerGroupPanelDetails);

export const ContactPanel = ({mainRefresh, rowData, isOpened, changePanelOpened, changePanelPinned } ) => {

  const [data, setData] = useState<CustomerModel>(rowData);



  const onDataChanged = useCallback(data => {
    setData(data);
  }, []);

  useEffect(() => {
    setData(rowData);
  }, [rowData]);

  return (
    <ContactPanelWithLoadPanel
      mainRefresh={mainRefresh}
      contact={data}
      hasData={!!data}
      isOpened={isOpened}
      onDataChanged={onDataChanged}
      changePanelOpened={changePanelOpened}
      changePanelPinned={changePanelPinned}
      panelProps={{
        position: { of: '.panel' },
        container: '.panel'
      }}
    />
  );
};

