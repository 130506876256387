
import React, { useEffect, useState } from 'react';

import Form, { Item as FormItem, GroupItem, ColCountByScreen } from 'devextreme-react/form';
import {  CustomerUserModel } from '../../../types/crm-contact';
import { FormTextbox, FormPhotoUploader } from '../..';
import { getSizeQualifier } from '../../../utils/media-query';
import { CheckBox } from 'devextreme-react';
import { useTranslation } from 'react-i18next';

export const CustomerUserNewForm = ({ initData, onDataChanged }: { initData: CustomerUserModel, onDataChanged: (data) => void }) => {
  const [newContactData, setNewContactData] = useState<CustomerUserModel>({ ...initData });
  const [status, setStatus] = useState(false);
  const {t} = useTranslation();
  const statusUpdate = (bool:boolean) => {
    setStatus(bool);
    newContactData.userActive = status ? 1 : 0;
    onDataChanged(newContactData);

  }

  useEffect(() => {
    setNewContactData({ ...initData });
  }, [initData]);

  const updateField = (field: string) => (value) => {
    const newData = { ...newContactData, ...{ [field]: value } };
    newData.userActive = status ? 1 : 0;
    onDataChanged(newData);
    setNewContactData(newData);
  };


  return (
    <Form
      className='plain-styled-form'
      screenByWidth={getSizeQualifier}
    >
      <GroupItem>
      <FormItem>
        <CheckBox
              value={status}
              text={t("active")}
              onValueChanged={(e)=>{
                statusUpdate(e.value);
              }}

            />
        </FormItem>
        <FormItem>
          <FormTextbox
            label={t("userName")}
            value={newContactData.userName}
            isEditing={false}
            icon='user'
            onValueChange={updateField('userName')}
          />
        </FormItem>

        <FormItem>
          <FormTextbox
            value={newContactData.userEmail}
            isEditing={false}
            onValueChange={updateField('userEmail')}
            icon='email'
            label={t("email")}
          />
        </FormItem>

        <FormItem>
          <FormTextbox
            label={t("password")}
            value={newContactData.userPassword}
            isEditing={false}
            icon='key'
            onValueChange={updateField('userPassword')}

          />
        </FormItem>

        <FormItem>
          <FormTextbox
            value={newContactData.comment}
            isEditing={false}
            onValueChange={updateField('comment')}
            icon='comment'
            label={t("comment")}
          />
        </FormItem>
      </GroupItem>
    </Form>
  );
};
