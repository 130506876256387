
import React, { useEffect, useState, useCallback } from 'react';

import './ContactPanel.scss';
import { CustomerUserModel } from '../../../types/crm-contact';
import { withLoadPanel } from '../../../utils/withLoadPanel';
import { CustomerUserPanelDetails } from './CustomerUserPanelDetail';


const CustomerUserPanelWithLoadPanel = withLoadPanel(CustomerUserPanelDetails);

export const CustomerUserPanel = ({customerUserRefresh, rowData, isOpened, changePanelOpened, changePanelPinned } ) => {

  const [data, setData] = useState<CustomerUserModel>(rowData);

  

  const onDataChanged = useCallback(data => {
    setData(data);
  }, []);

  useEffect(() => {
    setData(rowData);
  }, [rowData]);

  return (
    <CustomerUserPanelWithLoadPanel
      customerUserRefresh={customerUserRefresh}
      contact={data}
      hasData={!!data}
      isOpened={isOpened}
      onDataChanged={onDataChanged}
      changePanelOpened={changePanelOpened}
      changePanelPinned={changePanelPinned}
      panelProps={{
        position: { of: '.panel' },
        container: '.panel'
      }}
    />
  );
};

