import React from 'react';

import { HashRouter as Router, useLocation, useNavigate } from 'react-router-dom';

import LoadPanel from 'devextreme-react/load-panel';

import { NavigationProvider } from './contexts/navigation';
import { AuthProvider, useAuth } from './contexts/auth';
import { useScreenSizeClass } from './utils/media-query';
import { Content } from './Content';
import { UnauthenticatedContent } from './UnauthenticatedContent';
/*
import 'devexpress-gantt/dist/dx-gantt.css';
import './styles.scss';
*/
import './theme/theme';
import '../src/styles.scss';

import { useThemeContext, ThemeContext } from './theme/theme';

import config from 'devextreme/core/config';
import { licenseKey } from './devextreme-key';
import { customersFromSession, getAppToken, getCustomerGroups } from './api/sessions';

import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import tr from './i18n/tr.json';
import en from './i18n/en.json';
import de from './i18n/de.json';
import nl from './i18n/nl.json';
import fr from './i18n/fr.json';
import it from './i18n/it.json';
import dk from './i18n/dk.json';
import sa from './i18n/sa.json';
import ir from './i18n/ir.json';
import { getLanguageFromCookie } from './api/cookies';
import { CustomerProvider } from './contexts/customers';

config({ licenseKey });

  function RootApp() {
  const { user, loading } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const isLoginForm = [
    'login',
    'reset-password',
    'create-account',
  ].includes(location.pathname.substring(1));

  var token = getAppToken();
  if (token!="" && token!="undefined" && token!=null) {
    return <Content />;
  }else{
    console.log("Token bulunamadı");
    return <UnauthenticatedContent />;
  }

}

// Dil ayarları
i18n
  .use(initReactI18next) // react-i18next'i başlatın
  .init({
    resources: {
      en: { translation: en },
      tr: { translation: tr },
      de: { translation: de },
      nl: { translation: nl },
      fr: { translation: fr },
      it: { translation: it },
      dk: { translation: dk },
      sa: { translation: sa },
      ir: { translation: ir },
    },
    lng: getLanguageFromCookie(), // varsayılan dil
    fallbackLng: 'en', // varsayılan dil
    interpolation: {
      escapeValue: false
    }
  });

export const App = () => {
  const screenSizeClass = useScreenSizeClass();
  const themeContext = useThemeContext();

  return (
    <Router>
      <CustomerProvider>
      <ThemeContext.Provider value={themeContext}>
        <AuthProvider>
          <NavigationProvider>

            <div className={`app ${screenSizeClass}`}>
              {
                themeContext.isLoaded ? <RootApp /> : ''
              }
            </div>
          </NavigationProvider>
        </AuthProvider>
      </ThemeContext.Provider>
      </CustomerProvider>
    </Router>
  );
};
