import React from 'react';

import { TickerCard } from '../../library/ticker-card/TickerCard';
import { TickerProps } from '../../../types/analytics';
import { euroFormat, } from '../../../utils/format-currency';

export const RevenueTotalTicker = ({ title,value,subtitle,percent,showPercentage }: TickerProps) =>
  <TickerCard
    title={title}
    subtitle={subtitle}
    icon='dataarea'
    value={value}
    formatValue={euroFormat}
    percentage={percent}
    showPercentage ={showPercentage}
  />;
