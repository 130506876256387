
import axios from 'axios';
import { CustomerUserModel } from '../types/crm-contact';
import { getAppToken, getVendorId } from './sessions';
import { appInfo } from '../app-info';

// login isteği

const apiUrl = appInfo.url;

export async function loginRequest(userName : string, password : string, vendorId : string){
  var request = await  axios.post(apiUrl, {
        serviceId: '1515',
        userName: userName,
        password: password,
        vendorId: vendorId,
        lang: "tr",
        deviceManifacturer: "",
        deviceModel:"",
        deviceSerial:"",
        deviceOSName:"",
        deviceOSVersion:"",
      });
      return request;
}



// tüm müşteriler isteği

export async function customersRequest(){
  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '1020',
        token: appToken,
        vendorId: appVendorID,
      });
      return request;
}



// müşterinin tüm kullancıları isteği

export async function customerUsersRequest(customerRef:number){
  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
    serviceId: "1030",
    vendorId: appVendorID,
    token: appToken,
    customerRef: customerRef,
      },);
      return request;
}



// müşteri gruplarını getir

export async function getCustomerGroupsRequest(token:string){
  var appVendorID = getVendorId();
  var request = await axios.post(
    apiUrl,
    {
  serviceId: "1040",
  vendorId: appVendorID,
  token: token,
    },

  );
      return request;
}


// müşteri güncelle

export async function customerUpdateRequest(
  customerRef:number,
  customerActive:number,
  customerCode:string,
  customerName:string,
  customerGroupRef:number,
  erpCustomerRef:number,
  erpShippingAdrRef:number,
  productGroupRef: number
) {
  var appToken = getAppToken();
  var appVendorID = getVendorId();

  var request =  await axios.post(
    apiUrl,
    {
  serviceId: "1021",
  vendorId: appVendorID,
  token: appToken,
  customerRef: customerRef,
  customerActive: customerActive,
  customerCode: customerCode,
  customerName: customerName,
  customerGroupRef: customerGroupRef,
  erpCustomerRef: erpCustomerRef,
  erpShippingAdrRef: erpShippingAdrRef,
  productGroupRef: productGroupRef
    },
  );
  return request.data;
}


//yeni customerUser ekle

export async function newCustomerUserRequest(model:CustomerUserModel) {
  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await axios.post(
    apiUrl,
    {
      serviceId: "1031",
      vendorId: appVendorID,
      token: appToken,
       comment: model.comment,
        customerRef: model.customerRef,
        customerUserRef: model.customerUserRef,
        userRef: model.userRef,
        userActive: model.userActive,
        userName: model.userName,
        userEmail: model.userEmail,
        userPassword: model.userPassword,

    }
  );
  return request.data;
}


//exports list

export async function exportsListRequest(beginDate,endDate,docType) {
  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await axios.post(
    apiUrl,
    {
      serviceId: "1601",
      vendorId: appVendorID,
      token: appToken,
      beginDate: beginDate,
      endDate: endDate,
      docType: docType

    }
  );
  return request.data;
}


// dashboard1

export async function dashboard1(begin,end) {
  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await axios.post(
    apiUrl,
    {
      serviceId: "1624",
      vendorId: appVendorID,
      token: appToken,
      beginDate: begin,
      endDate: end
    }
  );
  return request.data;
}




// dashboard2

export async function dashboard2Request(index) {
  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await axios.post(
    apiUrl,
    {
      serviceId: "1626",
      vendorId: appVendorID,
      token: appToken,
      dashboardIndex: index
    }
  );
  return request.data;
}



// dashboard1625

export async function dashboard1625(begin,end) {
  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await axios.post(
    apiUrl,
    {
      serviceId: "1625",
      vendorId: appVendorID,
      token: appToken,
      beginDate: begin,
      endDate: end
    }
  );
  return request.data;
}




// müşteri grupları isteği

export async function customerGroupsRequest(){
  var appToken = getAppToken();
  var appVendorID = getVendorId();
  var request = await  axios.post(apiUrl, {
        serviceId: '1040',
        token: appToken,
        vendorId: appVendorID,
      });
      return request;
}
