import React, { useState, useEffect, useCallback, useRef } from 'react';
import { jsPDF as JsPdf } from 'jspdf';
import { saveAs } from 'file-saver-es';
import { Workbook } from 'exceljs';
import './erp-exports.scss';
import DataGrid, {
  Sorting, Selection, HeaderFilter, Scrolling, SearchPanel,
  ColumnChooser, Export, Column, Toolbar, Item, LoadPanel,
  DataGridTypes
} from 'devextreme-react/data-grid';
import Button from 'devextreme-react/button';

import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { exportDataGrid as exportDataGridToXLSX } from 'devextreme/excel_exporter';

import { ContactStatus as ContactStatusType, CustomerModel } from '../../types/crm-contact';
import DataSource from 'devextreme/data/data_source';
import {  exportsListRequest } from '../../api/requests';
import { useTranslation } from 'react-i18next';
import { DateBox } from 'devextreme-react';


const onExporting = (e: DataGridTypes.ExportingEvent) => {
  if (e.format === 'pdf') {
    const doc = new JsPdf();
    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: e.component,
    }).then(() => {
      doc.save('Customers.pdf');
    });
  } else {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Contacts');

    exportDataGridToXLSX({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Customers.xlsx');
      });
    });
    e.cancel = true;
  }
};

const exportFormats = ['xlsx', 'pdf'];

export const ERPExports = () => {
  const { t } = useTranslation();
  const [gridDataSource, setGridDataSource] = useState<DataSource<CustomerModel[], string>>();
  const gridRef = useRef<DataGrid>(null);

  const [listIsEmpty, setListIsEmpty] = useState(false);

  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  const currentDate = `${year}-${month}-${day}`;
  const [bDate, setBDate] = useState(currentDate);
  const [eDate, setEDate] = useState(currentDate);

  function apiCall(beginDate,endDate,docType){
    exportsListRequest(beginDate,endDate,docType).then((data)=>{
      if(!data.dataSet){
        setListIsEmpty(true);
      }else{
        setListIsEmpty(false);
      }
      setGridDataSource(new DataSource({
        key: 'f1',
        load: () => data.dataSet,
      }));

    }).catch(error => {
      console.error(error);
    });
  }

useEffect(()=>{

    apiCall(bDate,eDate,1);

},[])


  const refresh = useCallback(() => {
    gridRef.current?.instance.beginCustomLoading(t("loading"))

  }, []);


  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return `${year}-${month}-${day}`;
  };



  return (
    <div className='view crm-contact-list'>
      <div className='view-wrapper view-wrapper-contact-list list-page'>
        <DataGrid
          className='grid theme-dependent'
          noDataText ={listIsEmpty ? t("noData") : t("loading")}
          height='100%'
          focusedRowEnabled
          dataSource={gridDataSource}
          onExporting={onExporting}
          allowColumnReordering
          showBorders
          ref={gridRef}>

          <LoadPanel showPane={false}  />
          <SearchPanel visible placeholder={t("customerSearch")}  />
          <ColumnChooser enabled />
          <Export enabled allowExportSelectedData formats={exportFormats} />
          <Selection
            selectAllMode='allPages'
            showCheckBoxesMode='always'
            mode='multiple'
          />
          <HeaderFilter visible />
          <Sorting mode='single' />
          <Scrolling mode='virtual' />
          <Toolbar>
          <Item name='searchPanel' location='before' />

          <Item location='before' locateInMenu='auto'>
          <DateBox defaultValue={bDate} onValueChanged={(e) => setBDate(formatDate(e.value))} displayFormat="yyyy-MM-dd" />
          </Item>

          <Item location='before' locateInMenu='auto'>
          <DateBox defaultValue={eDate} onValueChanged={(e) => setEDate(formatDate(e.value))} displayFormat="yyyy-MM-dd" />
          </Item>

          <Item location='before' locateInMenu='auto'>
          <Button text={t("getList")} onClick={() => apiCall(bDate, eDate, 1)} />
          </Item>

            <Item
              location='after'
              locateInMenu='auto'
              showText='inMenu'
              widget='dxButton'
            >
              <Button
                icon='refresh'
                text='Refresh'
                stylingMode='text'
                onClick={refresh}
              />
            </Item>
            <Item location='after' locateInMenu='auto'>
              <div className='separator' />
            </Item>
            <Item name='exportButton' />
            <Item location='after' locateInMenu='auto'>
              <div className='separator' />
            </Item>
            <Item name='columnChooserButton' locateInMenu='auto' />

          </Toolbar>

          <Column
            dataField='f14'
            caption="f14"
            width={250}
          />

          <Column
            dataField='f1'
            caption="f1"
          />

          <Column
            dataField='f2'
            caption="f2"
          />

          <Column
            dataField='f3'
            caption="f3"
          />

          <Column
            dataField='f7'
            caption="f7"
          />

          <Column
            dataField='f8'
            caption="f8"
          />

          <Column
            dataField='f10'
            caption="f10"
          />

          <Column
            dataField='f11'
            caption="f11"
          />

          <Column
            dataField='f114'
            caption="f114"
          />

        </DataGrid>

      </div>
    </div>
  );
};
