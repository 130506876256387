import React, { createContext, useContext, useEffect, useState } from 'react';
import { CustomerModel } from '../types/crm-contact';
import { customersToSession, getAppToken } from '../api/sessions';
import { customersRequest } from '../api/requests';

// Context oluştur
const CustomerContext = createContext<{
    customers: CustomerModel[];
    setCustomers: React.Dispatch<React.SetStateAction<CustomerModel[]>>;
  }>({ customers: [], setCustomers: () => {} });

  // Context'in değerlerine erişmek için bir hook oluştur
  export const useCustomerContext = () => useContext(CustomerContext);

  // Context'in sağlayıcısı bileşeni
  export const CustomerProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [customers, setCustomers] = useState<CustomerModel[]>([]);

    useEffect(() => {
        if(getAppToken() != ""){
            customersRequest().then((e)=>{
                setCustomers(e.data.dataSet);
                customersToSession(JSON.stringify(e.data.dataSet))
              });
        }


      }, []);

      useEffect(() => {
        console.log("testtt")
        const serializedCustomers = JSON.stringify(customers);
       // customersToSession(serializedCustomers);
      }, [setCustomers]);


    return (
      <CustomerContext.Provider value={{ customers, setCustomers }}>
        {children}
      </CustomerContext.Provider>
    );
  };