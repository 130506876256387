import {
  PlanningScheduler,
  PlanningTaskDetails,
  AnalyticsDashboard,
  ERPExports,
  AnalyticsSalesReport,
  AnalyticsGeography,
  SignInPage,
  SignUpPage,
  ResetPasswordPage,
  UserProfile,
  B2BCustomerList,
  B2BCustomerGroups,
  B2BCustomerDetails
} from './pages';
import { withNavigationWatcher } from './contexts/navigation';

const routes = [
  {
    path: '/b2b-customer-details',
    element: B2BCustomerDetails,
  },
  {
    path: '/b2b-customer-list',
    element: B2BCustomerList,
  },
  {
    path: '/b2b-customer-groups',
    element: B2BCustomerGroups,
  },
  {
    path: '/erp-exports',
    element: ERPExports,
  },
  {
    path: '/planning-task-details',
    element: PlanningTaskDetails,
  },
  {
    path: '/planning-scheduler',
    element: PlanningScheduler,
  },
  {
    path: '/analytics-dashboard',
    element: AnalyticsDashboard,
  },
  {
    path: '/analytics-sales-report',
    element: AnalyticsSalesReport,
  },
  {
    path: '/analytics-geography',
    element: AnalyticsGeography,
  },
  {
    path: '/sign-in-form',
    element: SignInPage,
  },
  {
    path: '/sign-up-form',
    element: SignUpPage,
  },
  {
    path: '/reset-password-form',
    element: ResetPasswordPage,
  },
  {
    path: '/user-profile',
    element: UserProfile,
  },
];

export const appRoutes = routes.map((route) => {
  return {
    ...route,
    element: withNavigationWatcher(route.element, route.path),
  };
});
