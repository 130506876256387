import React, { useState, useEffect, useCallback } from 'react';

import { ToolbarAnalytics, SalesRangeCard, SalesByRangeCard, SalesPerformanceCard } from '../../components';
import { Sale, SaleOrOpportunityByCategory } from '../../types/analytics';
import { formatDate } from 'devextreme/localization';
import LoadPanel from 'devextreme-react/load-panel';
import { RangeSelectorTypes } from 'devextreme-react/range-selector';
import { DropDownButtonTypes } from 'devextreme-react/drop-down-button';
import ScrollView from 'devextreme-react/scroll-view';

import './analytics-sales-report.scss';
import { dashboard1 } from '../../api/requests';

const formatDateRange = (dateRange: Date[]) => dateRange.map((date) => formatDate(date, 'yyyy-MM-dd'));

const today = new Date();
const lastYearToday = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());

const ANALYTICS_PERIODS2 = {
  "Year": {
    period: `${lastYearToday.toISOString().split('T')[0]}/${today.toISOString().split('T')[0]}`,
  }
};

const defaultDateRange = ANALYTICS_PERIODS2["Year"].period.split('/').map((d) => new Date(d));

const groupByPeriods = ['Day', 'Month'];

export const AnalyticsSalesReport = () => {
  const [sales, setSales] = useState([]);
  const [salesByCategory, setSalesByCategory] = useState<SaleOrOpportunityByCategory[]>([]);
  const [salesByDateAndCategory, setSalesByDateAndCategory] = useState<Sale[]>([]);
  const [dateRange, setDateRange] = useState(defaultDateRange);
  const [groupByPeriod, setGroupByPeriod] = useState(groupByPeriods[1]);
  const [isLoading, setIsLoading] = useState(true);
  const [loader, setLoader] = useState(true)


  const onRangeChanged = useCallback((e: RangeSelectorTypes.ValueChangedEvent) => {
    const [startDate, endDate] = e.value;
    setDateRange([startDate, endDate] as Date[]);
    setIsLoading(true);
  }, []);

  const onPeriodChanged = useCallback((e: DropDownButtonTypes.SelectionChangedEvent) => {
    setGroupByPeriod(e.item);
    setIsLoading(true);
  }, []);


  function d1(){
    console.log("tik")
    if(loader){
      setLoader(false)
      dashboard1(formatDateRange(dateRange)[0],formatDateRange(dateRange)[1]).then((data)=>{
        var list = data["salesItemCategories"];
        var list2 = data["salesCategoryTotals"];
        list2 = list2.slice(0,5);

        setSales(list)
        setSalesByDateAndCategory(list);
        setSalesByCategory(list2)

        setIsLoading(false);
        setTimeout(()=>{
          setLoader(true);
          setIsLoading(false);
           },1000)
      })
    }
  }

  useEffect(()=>{
    d1()
  },[dateRange])

/*
  useEffect(() => {
    getSalesByCategory(...formatDateRange(dateRange))
      .then((data) => {
        setSalesByCategory(data);
        setIsLoading(false);
      })
      .catch((error) => console.log(error));
  }, [dateRange]);

  useEffect(() => {
    getSalesByOrderDate(groupByPeriod.toLowerCase())
      .then((data) => {
        setSalesByDateAndCategory(data);
        setIsLoading(false);
      })
      .catch((error) => console.log(error));
  }, [groupByPeriod]);
*/
  return (
    <ScrollView className='view-wrapper-scroll'>
      <ToolbarAnalytics showOthers={false} title='Sales Report'>
        <div className='cards wide'>
          <SalesRangeCard datasource={sales} range={dateRange} onRangeChanged={onRangeChanged} />
          {
            /*
          <SalesByRangeCard datasource={salesByCategory} />

            */
          }
          <SalesPerformanceCard
            datasource={salesByDateAndCategory}
            periods={groupByPeriods}
            selectedPeriod={groupByPeriod}
            onPeriodChanged={onPeriodChanged}
            range={dateRange}
          />
        </div>
      </ToolbarAnalytics>
      <LoadPanel container='.content' visible={isLoading} position={{ of: '.layout-body' }} />
    </ScrollView>
  );
};
