import React from 'react';

import './ContactStatus.scss';
import { useTranslation } from 'react-i18next';

export const ContactStatus = ({ text, contentClass = '', showText = true }:
{ text: string, contentClass?: string, showText?: boolean })=> {
  const {t} = useTranslation();
  return (
  <div className={`status status-contact status-${text=="1"?"commission":"terminated"} ${contentClass}`}>
    <span>{showText ? (text=="1"?t("active"):t("passive")) : ''}</span>
  </div>
)};
