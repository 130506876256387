import { useTranslation } from "react-i18next";

// Dil değerini bir cookie olarak ayarlayan fonksiyon
 export function setLanguageCookie(language: string) {
    document.cookie = `language=${language}; expires=${new Date(Date.now() + 365 * 24 * 60 * 60 * 1000).toUTCString()}; path=/`;
  }

  // Dil değerini okuyan fonksiyon
 export function getLanguageFromCookie() {
    const cookies = document.cookie.split('; ');
    for (const cookie of cookies) {
      const [name, value] = cookie.split('=');
      if (name === 'language') {
        return value;
      }
    }
    return "en";
  }


  export function setStatusFilterCookie(filter: string) {
    document.cookie = `filter=${filter}; expires=${new Date(Date.now() + 365 * 24 * 60 * 60 * 1000).toUTCString()}; path=/`;
  }


 export function getStatusFilterCookie() {
  const {t} = useTranslation();
    const cookies = document.cookie.split('; ');
    for (const cookie of cookies) {
      const [name, value] = cookie.split('=');
      if (name === 'filter') {
        return value;
      }
    }
    return t("all");
  }
