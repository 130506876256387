import { Task } from '../types/task';
import { CustomerModel, CustomerUserModel } from '../types/crm-contact';
import { DateTime } from 'rrule/dist/esm/datetime';

export const PRIORITY_ITEMS = ['Low', 'Normal', 'High'];
export const STATUS_ITEMS = ['Open', 'In Progress', 'Deferred', 'Completed'];

export const ANALYTICS_PERIODS = {
  "Day": {
    period: calculatePeriod(1),
    index: 0,
  },
  "Week": {
    period: calculatePeriod(7),
    index: 1,
  },
  "Month": {
    period: calculatePeriod(30),
    index: 2,
  },
  "Year": {
    period: calculatePeriod(365),
    index: 3,
  }
};

export function calculatePeriod(daysAgo: number): string {
  const endDate = new Date();
  const startDate = new Date(endDate);
  startDate.setDate(startDate.getDate() - daysAgo);

  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);

  return `${formattedStartDate}/${formattedEndDate}`;
}

function formatDate(date: Date): string {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}


export const DEFAULT_ANALYTICS_PERIOD_KEY = 'Week';

export const CONTACT_STATUS_LIST = [
  'active',
  'passive',
];

export const CONTACT_STATUS_LIST2 = [
  1,
  0,
];

export const newTask: Task = {
  id: 0,
  text: '',
  description: '',
  company: '',
  priority: 'Low',
  startDate: new Date(),
  dueDate: new Date(),
  owner: '',
  status: 'Open',
  activities: [],
  notes: [],
  messages: [],
  parentId: 0,
  progress: 0,
};

export interface SalesDatum {
  currRevTotal: number;
  currentMonth: number;
  currentWeek: number;
  currentYear: number;
  date: DateTime;
  category: String;
}

export const newCustomerUserModel: CustomerUserModel = {
  customerUserRef: 0,
  customerRef: 0,
  userRef: 0,
  userActive: 0,
  userName: "",
  userEmail: "",
  userPassword: "",
  comment: ""
}

export const newContact: CustomerModel = {
id: 0,
comment:"",
customerActive:0,
customerCode:"",
customerGroupCode:"",
customerGroupName:"",
customerGroupRef:0,
customerName:"",
customerRef:0,
erpCustomerAddr1:"",
erpCustomerCity:"",
erpCustomerCode:"",
erpCustomerName1:"",
erpCustomerPostCode:"",
erpCustomerRef:0,
erpShippingAdrAddr1:"",
erpShippingAdrCity:"",
erpShippingAdrCode:"",
erpShippingAdrName1:"",
erpShippingAdrPostCode:"",
erpShippingAdrRef:0,
productGroupCode:"",
productGroupName:"",
productGroupRef:0
};
