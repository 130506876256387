import React, { useState, useEffect, useCallback, useRef } from 'react';
import { jsPDF as JsPdf } from 'jspdf';
import { saveAs } from 'file-saver-es';
import { Workbook } from 'exceljs';
import './b2b-customer-list.scss';
import DataGrid, {
  Sorting, Selection, HeaderFilter, Scrolling, SearchPanel,
  ColumnChooser, Export, Column, Toolbar, Item, LoadPanel,
  DataGridTypes
} from 'devextreme-react/data-grid';

import SelectBox from 'devextreme-react/select-box';
import TextBox from 'devextreme-react/text-box';
import Button from 'devextreme-react/button';
import DropDownButton, { DropDownButtonTypes } from 'devextreme-react/drop-down-button';

import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { exportDataGrid as exportDataGridToXLSX } from 'devextreme/excel_exporter';

import { ContactStatus as ContactStatusType, CustomerModel } from '../../types/crm-contact';

import { ContactPanel } from '../../components';
import { ContactStatus } from '../../components';

import { CONTACT_STATUS_LIST, newContact } from '../../shared/constants';
import DataSource from 'devextreme/data/data_source';
import notify from 'devextreme/ui/notify';
import { customersRequest } from '../../api/requests';
import { customerListFilterFromSession, customerListFilterToSession, customerListSelectFromSession, customerListSelectToSession, customersToSession } from '../../api/sessions';
import { useTranslation } from 'react-i18next';
import { useCustomerContext } from '../../contexts/customers';
import { setStatusFilterCookie } from '../../api/cookies';



type FilterContactStatus = ContactStatusType | 'Active';


const cellNameRender = (cell: DataGridTypes.ColumnCellTemplateData) => (
  <div className='name-template'>
    <div>{cell.data.customerName}</div>
    <div className='position'>{cell.data.position}</div>
  </div>
);

const editCellStatusRender = () => (
  <SelectBox className='cell-info' dataSource={CONTACT_STATUS_LIST} itemRender={ContactStatus} fieldRender={fieldRender} />
);

const fieldRender = (text: string) => (
  <>
    <ContactStatus text={text} />
    <TextBox readOnly />
  </>
);

const onExporting = (e: DataGridTypes.ExportingEvent) => {
  if (e.format === 'pdf') {
    const doc = new JsPdf();
    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: e.component,
    }).then(() => {
      doc.save('Customers.pdf');
    });
  } else {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Contacts');

    exportDataGridToXLSX({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Customers.xlsx');
      });
    });
    e.cancel = true;
  }
};

const dropDownOptions = { width: 'auto' };
const exportFormats = ['xlsx', 'pdf'];

export const B2BCustomerList = () => {
  const { t } = useTranslation();
  const { customers, setCustomers } = useCustomerContext();
  const filterStatusList = [t("all"), t("active"), t("passive")];
  const [gridDataSource, setGridDataSource] = useState<DataSource<CustomerModel[], string>>();
  const [isPanelOpened, setPanelOpened] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const [formDataDefaults, setFormDataDefaults] = useState({ ...newContact });
  const gridRef = useRef<DataGrid>(null);

  const [rowData, setRowData] = useState();
  const [listKey, setListKey] = useState<String>();

  let newContactData: CustomerModel;

  const [refreshKey, setRefreshKey] = useState(0);
  function mainRefresh(){
    setRefreshKey(a => a + 1);
    customersRequest().then((res)=>{
      setCustomers(res.data.dataSet);
      customersToSession(JSON.stringify(res.data.dataSet))
      gridRef.current?.instance.endCustomLoading();
    })
  }

  function refreshFromApi(){
    customersRequest()
    .then(response => {
      var data = response.data.dataSet;
     // customersToSession(JSON.stringify(data))
      setCustomers(data);
      setGridDataSource(new DataSource({
        key: 'combinedCode',
        load: () => {
          const modifiedCustomers = customers.map(customer => ({
            ...customer,
            combinedCode: `${customer.erpCustomerRef}-${customer.erpShippingAdrRef}`
          }));
          return JSON.parse(JSON.stringify(modifiedCustomers)); // API'den gelen veriyi yükle
        },
      }));

      setTimeout(() => {
        gridRef.current?.instance.filter(['customerActive', '=', "1"]);
      }, 100);

    })
    .catch(error => {
      console.error('Error fetching data:', error);
      // Hata durumunda gerekli işlemleri yap
    });
  }


  useEffect(() => {

if(customers.length==0){
  customersRequest().then((e)=>{
    setCustomers(e.data.dataSet);
    customersToSession(JSON.stringify(e.data.dataSet))
    setCustomerActive(customerListFilterFromSession() ?? t("active"))
    setTimeout(() => {
      if(customerActive == t("passive")){
        gridRef.current?.instance.filter(['customerActive', '=', "0"]);
      }else if(customerActive == t("active")){
        gridRef.current?.instance.filter(['customerActive', '=', "1"]);
      }else{
        gridRef.current?.instance.clearFilter()
      }
      setTimeout(()=>{
     //   gridRef.current?.instance.navigateToRow(customerListSelectFromSession());
      },100)

    }, 100);
  })
}else{

  setGridDataSource(new DataSource({
    key: 'combinedCode',
    load: () => {
      const modifiedCustomers = customers.map(customer => ({
        ...customer,
        combinedCode: `${customer.erpCustomerRef}-${customer.erpShippingAdrRef}`
      }));
      return JSON.parse(JSON.stringify(modifiedCustomers)); // API'den gelen veriyi yükle
    },
  }));

    setCustomerActive(customerListFilterFromSession() ?? t("active"))

    setTimeout(() => {
      if(customerActive == t("passive")){
        gridRef.current?.instance.filter(['customerActive', '=', "0"]);
      }else if(customerActive == t("active")){
        gridRef.current?.instance.filter(['customerActive', '=', "1"]);
      }else{
        gridRef.current?.instance.clearFilter()
      }
      setTimeout(()=>{
       // gridRef.current?.instance.navigateToRow(customerListSelectFromSession());
      },100)

    }, 100);
  }

  }, [JSON.stringify(customers)]);


  const changePopupVisibility = useCallback((isVisble) => {
    setPopupVisible(isVisble);
    console.log("changePopupVisibility")
  }, []);

  const changePanelOpened = useCallback(() => {
    setPanelOpened(!isPanelOpened);
    gridRef.current?.instance.navigateToRow(customerListSelectFromSession());
    console.log("changePanelOpened")
  }, [isPanelOpened]);

  const changePanelPinned = useCallback(() => {
    gridRef.current?.instance.updateDimensions();
  }, []);

  const onAddContactClick = useCallback(() => {
    setPopupVisible(true);
    console.log("onAddContactClick")
  }, []);

  const onRowClick = useCallback(({ data,rowIndex }: DataGridTypes.RowClickEvent) => {
    setRowData(data);
    setPanelOpened(true);
    var ss =  data.combinedCode;
    console.log(ss)
    customerListSelectToSession(ss);

  }, []);

  const [customerActive, setCustomerActive] = useState(customerListFilterFromSession()?? t("active"));

  const filterByStatus = useCallback((e: DropDownButtonTypes.SelectionChangedEvent) => {
    const { item: customerActive }: { item: FilterContactStatus } = e;

    if (customerActive === t("all")) {
      gridRef.current?.instance.clearFilter();
    } else {
      gridRef.current?.instance.filter(['customerActive', '=', customerActive==t("active")?"1":"0"]);
    }

    setCustomerActive(customerActive);
    customerListFilterToSession(customerActive)
    setStatusFilterCookie(customerActive)

  }, []);

  const refresh = useCallback(() => {
    mainRefresh();
    gridRef.current?.instance.beginCustomLoading(t("loading"))

  }, []);

  const onDataChanged = useCallback((data) => {
    newContactData = data;
  }, []);

  const onSaveClick = useCallback(() => {
    notify({
      message: `New contact saved`,
      position: { at: 'bottom center', my: 'bottom center' }
    },
    'success'
    );

    setFormDataDefaults({ ...formDataDefaults });
    setPopupVisible(false);
  }, []);

  return (
    <div className='view crm-contact-list'>
      <div className='view-wrapper view-wrapper-contact-list list-page'>
        <DataGrid
          className='grid theme-dependent'
          noDataText={t("loading")}
          height='100%'
          focusedRowEnabled
          dataSource={gridDataSource}
          onRowClick={onRowClick}
          onExporting={onExporting}
          allowColumnReordering
          showBorders
          ref={gridRef}>

          <LoadPanel showPane={false}  />
          <SearchPanel visible placeholder={t("customerSearch")}  />
          <ColumnChooser enabled />
          <Export enabled allowExportSelectedData formats={exportFormats} />
          <Selection
            selectAllMode='allPages'
            showCheckBoxesMode='always'
            mode='multiple'
          />
          <HeaderFilter visible />
          <Sorting mode='multiple' />
          <Scrolling mode='virtual' />
          <Toolbar>
          <Item name='searchPanel' location='before' />

            <Item location='before' locateInMenu='auto'>
              <DropDownButton
                items={filterStatusList}
                stylingMode='text'
                text={customerActive}
                dropDownOptions={dropDownOptions}
                useSelectMode
                onSelectionChanged={filterByStatus}
              />
            </Item>
            <Item location='after' locateInMenu='auto'>
              <Button
                icon='plus'
                text='Add Contact'
                type='default'
                stylingMode='contained'
                visible={false}
                onClick={onAddContactClick}
              />
            </Item>
            <Item
              location='after'
              locateInMenu='auto'
              showText='inMenu'
              widget='dxButton'
            >
              <Button
                icon='refresh'
                text='Refresh'
                stylingMode='text'
                onClick={refresh}
              />
            </Item>
            <Item location='after' locateInMenu='auto'>
              <div className='separator' />
            </Item>
            <Item name='exportButton' />
            <Item location='after' locateInMenu='auto'>
              <div className='separator' />
            </Item>
            <Item name='columnChooserButton' locateInMenu='auto' />

          </Toolbar>
          <Column
            dataField='customerActive'
            caption={t("status")}
            hidingPriority={19}
            width={100}
            cellRender={ContactStatus}
          />
          <Column
            dataField='customerCode'
            caption={t("customerCode")}
            sortOrder='asc'
            width={300}
            hidingPriority={18}
          />
          <Column
            dataField='customerName'
            caption={t("name")}
            hidingPriority={20}
            cellRender={cellNameRender}
          />
          <Column
            dataField='erpCustomerCity'
            caption={t("city")}
            hidingPriority={17}
          />

          <Column
            dataField='customerGroupName'
            caption="Customer Group Name"
            hidingPriority={16}
          />

          <Column
            dataField='customerGroupCode'
            caption="Customer Group Code"
            hidingPriority={15}
          />

          <Column
            dataField='productGroupName'
            caption="Product Group Name"
            hidingPriority={14}
          />

          <Column
            dataField='productGroupCode'
            caption="Product Group Code"
            hidingPriority={13}
          />

          <Column
            dataField='comment'
            caption="Comment"
            hidingPriority={12}
          />


        </DataGrid>
        <ContactPanel mainRefresh={mainRefresh}  rowData={rowData} isOpened={isPanelOpened} changePanelOpened={changePanelOpened} changePanelPinned={changePanelPinned} />

      </div>
    </div>
  );
};
