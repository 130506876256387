import React, { useState, useEffect, useCallback, useRef } from 'react';
import { jsPDF as JsPdf } from 'jspdf';
import { saveAs } from 'file-saver-es';
import { Workbook } from 'exceljs';

import './crm-contact-list.scss';

import DataGrid, {
  Sorting, Selection, HeaderFilter, Scrolling, SearchPanel,
  ColumnChooser, Export, Column, Toolbar, Item, LoadPanel,
  DataGridTypes
} from 'devextreme-react/data-grid';

import SelectBox from 'devextreme-react/select-box';
import TextBox from 'devextreme-react/text-box';
import Button from 'devextreme-react/button';
import DropDownButton, { DropDownButtonTypes } from 'devextreme-react/drop-down-button';

import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { exportDataGrid as exportDataGridToXLSX } from 'devextreme/excel_exporter';

import { ContactStatus as ContactStatusType, CustomerModel, CustomerUserModel } from "../../../types/crm-contact";

import { FormPopup, ContactNewForm, ContactPanel } from '../../../components';
import { ContactStatus } from '../../../components';

import { CONTACT_STATUS_LIST, newContact, newCustomerUserModel } from '../../../shared/constants';
import DataSource from 'devextreme/data/data_source';
import notify from 'devextreme/ui/notify';
import { customerUsersRequest, customersRequest, newCustomerUserRequest } from '../../../api/requests';
import { CustomerUserPanel } from './CustomerUserPanel';
import { CustomerUserNewForm } from './CustomerUserNewForm';
import { useTranslation } from 'react-i18next';

type FilterContactStatus = ContactStatusType | 'Active';

const cellNameRender = (cell: DataGridTypes.ColumnCellTemplateData) => (
  <div className='name-template'>
    <div>{cell.data.userName}</div>
    <div className='position'>{cell.data.position}</div>
  </div>
);

const editCellStatusRender = () => (
  <SelectBox className='cell-info' dataSource={CONTACT_STATUS_LIST} itemRender={ContactStatus} fieldRender={fieldRender} />
);

const fieldRender = (text: string) => (
  <>
    <ContactStatus text={text} />
    <TextBox readOnly />
  </>
);

const onExporting = (e: DataGridTypes.ExportingEvent) => {
  if (e.format === 'pdf') {
    const doc = new JsPdf();
    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: e.component,
    }).then(() => {
      doc.save('Contacts.pdf');
    });
  } else {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Contacts');

    exportDataGridToXLSX({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'CustomerUsers.xlsx');
      });
    });
    e.cancel = true;
  }
};

const dropDownOptions = { width: 'auto' };
const exportFormats = ['xlsx', 'pdf'];

export const CustomerUserList = ({customerRef}) => {
  const {t} = useTranslation();
  const filterStatusList = [t("all"), t("active"), t("passive")];
  const [gridDataSource, setGridDataSource] = useState<DataSource<CustomerModel[], string>>();
  const [isPanelOpened, setPanelOpened] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const [formDataDefaults, setFormDataDefaults] = useState<CustomerUserModel>(newCustomerUserModel);
  const gridRef = useRef<DataGrid>(null);

  const [rowData, setRowData] = useState();

  let newContactData: CustomerUserModel;

  const [refreshKey, setRefreshKey] = useState(0);
  function customerUserRefresh(){
    setRefreshKey(prevKey => prevKey + 1);
  }

  useEffect(() => {
    customerUsersRequest(customerRef)
      .then(response => {
        // API'den gelen veriye göre column'ları ayarla
        const columns = [
          { dataField: 'userName', caption: t("userName") },
          { dataField: 'customerUserRef', caption: t("code") },
          { dataField: 'userActive', caption: t("status") },

        ];

        setGridDataSource(new DataSource({
          key: 'customerUserRef',
          load: () => response.data.dataSet, // API'den gelen veriyi yükle
        }));

        // Column'ları ayarla
        gridRef.current?.instance.beginUpdate();
        gridRef.current?.instance.option('columns', columns);
        gridRef.current?.instance.endUpdate();
        setTimeout(() => {
        gridRef.current?.instance.filter(['userActive', '=', "1"]);
        setuserActive(t("active"))
        }, 100);

      })
      .catch(error => {
        console.error('Error fetching data:', error);
        // Hata durumunda gerekli işlemleri yap
      });
  }, [refreshKey]);

  const changePopupVisibility = useCallback((isVisble) => {
    setPopupVisible(isVisble);
  }, []);

  const changePanelOpened = useCallback(() => {
    setPanelOpened(!isPanelOpened);
    gridRef.current?.instance.option('focusedRowIndex', -1);
  }, [isPanelOpened]);

  const changePanelPinned = useCallback(() => {
    gridRef.current?.instance.updateDimensions();
  }, []);

  const onAddContactClick = useCallback(() => {
    setPopupVisible(true);
  }, []);

  const onRowClick = useCallback(({ data }: DataGridTypes.RowClickEvent) => {
    data.customerRef = customerRef;
    setRowData(data);
    setPanelOpened(true);
  }, []);

  const [userActive, setuserActive] = useState(filterStatusList[1]);

  const filterByStatus = useCallback((e: DropDownButtonTypes.SelectionChangedEvent) => {
    const { item: userActive }: { item: FilterContactStatus } = e;
    if (userActive === t("all")) {
      gridRef.current?.instance.clearFilter();
    } else {
      gridRef.current?.instance.filter(['userActive', '=', userActive==t("active")?"1":"0"]);
    }

    setuserActive(userActive);
  }, []);

  const refresh = useCallback(() => {
    customerUserRefresh();
  }, []);

  const onDataChanged = useCallback((data) => {
    newContactData = data;
  }, []);

  const onSaveClick = useCallback(() => {

    newContactData.customerRef = customerRef;

    newCustomerUserRequest(newContactData).then((res)=>{
      if(res.resCode == 200){
        customerUserRefresh();
        notify({
          message: "Kullanıcı başarıyla eklendi",
          position: { at: 'bottom center', my: 'bottom center' }
        },'success');
        setFormDataDefaults({ ...formDataDefaults });
    setPopupVisible(false);

      }else{
        notify({
          message: res.resMessage,
          position: { at: 'bottom center', my: 'bottom center' }
        },'error');
      }
    });


  }, []);

  return (
    <div className=''>
      <div className='view-wrapper view-wrapper-contact-list list-page'>
        <DataGrid
          className='grid theme-dependent'
          noDataText=''
          focusedRowEnabled
          height='100%'
          dataSource={gridDataSource}
          onRowClick={onRowClick}
          onExporting={onExporting}
          allowColumnReordering
          showBorders
          ref={gridRef}
        >
          <LoadPanel showPane={false} />
          <SearchPanel visible placeholder={t("userSearch")} />
          <ColumnChooser enabled />
          <Export enabled allowExportSelectedData formats={exportFormats} />
          <Selection
            selectAllMode='allPages'
            showCheckBoxesMode='always'
            mode='multiple'
          />
          <HeaderFilter visible />
          <Sorting mode='multiple' />
          <Scrolling mode='virtual' />
          <Toolbar>
            <Item location='before'>
              <div className='grid-header'>{t("users")}</div>
            </Item>
            <Item location='before' locateInMenu='auto'>
              <DropDownButton
                items={filterStatusList}
                stylingMode='text'
                text={userActive}
                dropDownOptions={dropDownOptions}
                useSelectMode
                onSelectionChanged={filterByStatus}
              />
            </Item>
            <Item name='searchPanel' locateInMenu='auto' location='before' />
            <Item location='after' locateInMenu='auto'>
              <Button
                icon='plus'
                text={t("addUser")}
                type='default'
                stylingMode='contained'
                onClick={onAddContactClick}
              />
            </Item>
            <Item
              location='after'
              locateInMenu='auto'
              showText='inMenu'
              widget='dxButton'
            >
              <Button
                icon='refresh'
                text='Refresh'
                stylingMode='text'
                onClick={refresh}
              />
            </Item>
            <Item location='after' locateInMenu='auto'>
              <div className='separator' />
            </Item>
            <Item name='exportButton' />
            <Item location='after' locateInMenu='auto'>
              <div className='separator' />
            </Item>
            <Item name='columnChooserButton' locateInMenu='auto' />

          </Toolbar>
          <Column
            dataField='userActive'
            caption={t("status")}
            dataType='string'
            hidingPriority={4}
            cellRender={ContactStatus}
          />
          <Column
            dataField='userName'
            caption={t("userName")}
            hidingPriority={5}
            cellRender={cellNameRender}
          />
          <Column
            dataField='userPassword'
            caption={t("password")}
            sortOrder='asc'
            hidingPriority={3}
            visible={false}
          />
          <Column
            dataField='customerRef'
            caption={t("code")}
            visible={false}
          />
          <Column
            dataField='userEmail'
            caption={t("email")}
            hidingPriority={3}
          />
        </DataGrid>
        <CustomerUserPanel customerUserRefresh={customerUserRefresh} rowData={rowData} isOpened={isPanelOpened} changePanelOpened={changePanelOpened} changePanelPinned={changePanelPinned} />
        <FormPopup title={t("newUser")} visible={popupVisible} setVisible={changePopupVisibility} onSave={onSaveClick}>
          <CustomerUserNewForm initData={ formDataDefaults } onDataChanged={onDataChanged} />
        </FormPopup>
      </div>
    </div>
  );
};
