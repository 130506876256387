import React, { useCallback, useEffect, useState } from 'react';
import { Item } from 'devextreme-react/toolbar';
import Tabs from 'devextreme-react/tabs';
import { LoadPanel } from 'devextreme-react/load-panel';
import ScrollView from 'devextreme-react/scroll-view';
import { useScreenSize } from '../../utils/media-query';
import {
  ToolbarAnalytics,
  RevenueSnapshotCard,
  RevenueCard,
  ConversionTicker,
  LeadsTicker,
  OpportunitiesTicker,
  RevenueTotalTicker,
} from '../../components';
import {  ANALYTICS_PERIODS, DEFAULT_ANALYTICS_PERIOD_KEY, SalesDatum, calculatePeriod } from '../../shared/constants';
import { SaleOrOpportunityByCategory } from '../../types/analytics';
import './analytics-dashboard.scss';
import { dashboard2Request } from '../../api/requests';
import { useTranslation } from 'react-i18next';
import { euroFormat } from '../../utils/format-currency';
import CitiesGraph from '../../components/utils/cities-graph/CitiesGraph';
import ReturnsGraph from '../../components/utils/returns-graph/ReturnsGraph';
import SalesGraph from '../../components/utils/sales-graph/SalesGraph';
import BarGraph from '../../components/utils/bar-graph/bar-graph';




export const AnalyticsDashboard = () => {
  const { t } = useTranslation();

   const ANALYTICS_PERIODS2 = {
   [t("day")]: {
      period: calculatePeriod(1),
      index: 0,
    },
    [t("week")]: {
      period: calculatePeriod(7),
      index: 1,
    },
    [t("month")]: {
      period: calculatePeriod(30),
      index: 2,
    },
    [t("year")]: {
      period: calculatePeriod(365),
      index: 3,
    }
  };
  const items = Object.keys(ANALYTICS_PERIODS);
  const [tabIndex, setTabIndex] = useState(1);
  const [dateRange, setDateRange] = useState(ANALYTICS_PERIODS["Week"].period.split('/'));
  const [productTotals, setProductTotals] = useState<SaleOrOpportunityByCategory[]>([]);
  const [salesByCategory, setSalesByCategory] = useState<SaleOrOpportunityByCategory[]>([]);
  const [currentSales, setCurrentSales] = useState<SalesDatum[]>([]);
  const [prevSales, setPrevSales] = useState<SalesDatum[]>([]);
  const [cityData, setCityData] = useState([]);
  const [salesTotal, setSalesTotal] = useState(0);
  const [prevSalesTotal, setPrevSalesTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [tabsWidth, setTabsWidth] = useState<number | string>('auto');

  var tabloShow = true;
  const defaultDateRange = ANALYTICS_PERIODS["Week"].period.split('/').map((d) => new Date(d));
  const [dateRange2, setDateRange2] = useState(defaultDateRange);
  const { isXSmall } = useScreenSize();

  const [cashOnHand1, setCashOnHand1] = useState(0);
  const [bankBalance1, setBankBalance1] = useState(0);
  const [currOrderTotals1, setCurrOrderTotals1] = useState(0);
  const [prevOrderTotals1, setPrevOrderTotals1] = useState(0);

  useEffect(() => {
      update2(tabIndex);
  }, []);

  function update2(index){
      dashboard2Request(index).then((apiData)=>{
        setIsLoading(false);
        const salesData = apiData["currentRevenue"]??[];
        const prevData = apiData["previousRevenue"]??[];

        try{
          if(index==0){}else{
            setPrevSales(prevData??[]);
            setCurrentSales(salesData??[]);
          }
        }catch(e){
            console.log(e)
        }

      try{
        //current revenue total
        let totalRevenue = 0;
        for (const revenue of salesData) {
            totalRevenue += revenue["currRevTotal"];
        }
        setSalesTotal(totalRevenue);
      }catch(e){
        setSalesTotal(0);
        console.log(e)
      }

      try{
        //previous revenue total
        let totalPrevRevenue = 0;
        for (const revenue of prevData) {
            totalPrevRevenue += revenue["prevRevTotal"];
        }
        setPrevSalesTotal(totalPrevRevenue);
      }catch(e){
        setSalesTotal(0);
        console.log(e)
      }



      setCashOnHand1(apiData["reportCashOnHand"][0]["cashBalance"] ?? 0);
      setBankBalance1(apiData["reportCashOnHand"][0]["bankBalance"] ?? 0);
      setCurrOrderTotals1(apiData["salesOrderTotals"][0]["currOrdTotals"] ?? 0);
      setPrevOrderTotals1(apiData["salesOrderTotals"][0]["prevOrdTotals"] ?? 0);

      try{

       //city data
       var cities = apiData["salesClientTotals"];
       cities = cities.sort((a,b)=> b.clientTotal - a.clientTotal);
       const newCities = cities.map(item=>{
        const newItem = {"arg": item["topClientName"],"val": item["clientTotal"]}
        return newItem;
       })
       setCityData(newCities)
      }catch(e){
        setCityData([]);
        console.log(e)
      }

      try{
          // returns
       var returnsGraph = apiData["salesProductTotals"];
       returnsGraph.sort((a, b) => b.productTotal - a.productTotal);
       const newList2 = returnsGraph.slice(0, 5);
       setProductTotals(newList2);
      }catch(e){
        setProductTotals([]);
        console.log(e)
      }

      try{
        // sales totals
      var pie_graph_data = apiData["salesGroupTotals"];
      pie_graph_data.sort((a, b) => b.categoryTotal - a.categoryTotal);
      const otherTotal = pie_graph_data.slice(4).reduce((acc, curr) => acc + curr.categoryTotal, 0);
      const newList = pie_graph_data.slice(0, 4);
      newList.push({ "reportCategory": t("others"), "categoryTotal": otherTotal });
      setSalesByCategory(newList);

      }catch(e){
        setSalesByCategory([]);
        console.log(e)
      }
    })
  }


  const onTabClick = useCallback((e) => {
    const { index, period } = ANALYTICS_PERIODS[e.addedItems[0]];
    setTabIndex(index);
    setDateRange(period.split('/'));
    setDateRange2(period.split('/'));
    if(index==0){
      update2(4)
    }else{
      update2(index)
    }

    setIsLoading(true);
  }, []);

  useEffect(() => {
    setTabsWidth(isXSmall ? 150 : 'auto');
  }, []);

  return (
    <ScrollView className='view-wrapper-scroll'>
      <ToolbarAnalytics
      showOthers={false}
        title={t('Dashboard')}
        additionalToolbarContent={
          <Item
            location='before'
          >
            <Tabs
              width={tabsWidth}
              scrollByContent
              showNavButtons={false}
              dataSource={items}
              selectedIndex={tabIndex}
              onSelectionChanged={onTabClick}
            />
          </Item>
        }
      >
          <div className='cards '>
          <RevenueTotalTicker title={t("revenue")} showPercentage subtitle={euroFormat(prevSalesTotal)} value={salesTotal}
          percent={parseFloat(((salesTotal - prevSalesTotal) / prevSalesTotal * 100).toFixed(1))} />
          <LeadsTicker title={t("salesOrders")} showPercentage subtitle={euroFormat(prevOrderTotals1)} value={currOrderTotals1}
          percent={parseFloat(((currOrderTotals1 - prevOrderTotals1) / prevOrderTotals1 * 100).toFixed(1))}/>
          </div>
{
  /* // className='compact' 4'lü görünüm için
   <OpportunitiesTicker showPercentage subtitle='' value={cashOnHand1} percent={0} />
   <ConversionTicker showPercentage subtitle='' value={bankBalance1} percent={0} />
  */
}


        <div className='cards normal'>
        {tabIndex == 1 || tabIndex == 0 ? (
            <BarGraph title={t("revenue")} salesData1={currentSales} salesData2={prevSales} index={tabIndex}></BarGraph>
          ) : (
            <SalesGraph title={t("revenue")} salesData1={currentSales} salesData2={prevSales} index={tabIndex}></SalesGraph>
          )}
        <RevenueSnapshotCard title={t("topCategories")} datasource={salesByCategory} />
        <CitiesGraph title={t("topCustomers")} cityData={cityData}></CitiesGraph>
        <ReturnsGraph title={t("topProducts")} returnsData={productTotals}></ReturnsGraph>

</div>


{
  /*

  <RevenueCard datasource={currentSales} />
  <br></br>
        <div className=' normal'>
        <DailyCard datasource={dailyReportData} />
        </div>
  */
}


      </ToolbarAnalytics>
      <LoadPanel container='.content' visible={isLoading} position={{ of: '.layout-body' }} />
    </ScrollView>
  );
};
