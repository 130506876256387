
import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckBox } from 'devextreme-react/check-box';
import classNames from 'classnames';
import { Button, ButtonTypes } from 'devextreme-react/button';
import { ScrollView } from 'devextreme-react/scroll-view';
import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';
import Form, { Item as FormItem, GroupItem, ColCountByScreen } from 'devextreme-react/form';
import { CustomerModel } from '../../../types/crm-contact';
import { FormTextbox, FormPhoto, ContactStatus } from '../..';
import { useScreenSize } from '../../../utils/media-query';
import SelectBox from 'devextreme-react/select-box';
import ValidationGroup from 'devextreme-react/validation-group';

import { useTranslation } from 'react-i18next';
import { useCustomerContext } from '../../../contexts/customers';
const GroupTemplate = (item) => <div>Assigned: {item.key}</div>;

const renderCustomTitle = (item) => {
  return (
    <>
      <span>{item.title}</span>
    </>
  );
};



export const CustomerGroupPanelDetails = ({mainRefresh, contact, isOpened, changePanelOpened, onDataChanged, changePanelPinned } : {mainRefresh:() => void, contact: CustomerModel, isOpened: boolean, changePanelOpened:(value: boolean)=> void, onDataChanged:(data)=> void, changePanelPinned:() => void }) => {
  const [isPinned, setIsPinned] = useState(false);
  const [isEditing, setIsEditing] = useState(true);
  const { isLarge, isMedium } = useScreenSize();

  const [customerGroupRef, setCustomerGroupRef] = useState(contact.customerGroupRef);

  const [userActive, setUserActive] = useState(contact.customerActive == 1 ? true : false);

  const navigate = useNavigate();

  const updateField = (field: string) => (value) => {
    onDataChanged({ ...contact, ...{ [field]: value } });
  };

  useEffect(() => {
    const storedData = sessionStorage.getItem('customerGroups');
    if (storedData) {
      var cg = JSON.parse(storedData);

      if(contact.customerGroupRef == 0){
        setCustomerGroupRef(cg[0]["customerGroupRef"]);
console.log(customerGroupRef)
        const selectBox = document.getElementById('customerGroups') as HTMLSelectElement | null;
        if (selectBox) {
            selectBox.value = customerGroupRef.toString();
        }
      }else{
        setCustomerGroupRef(contact.customerGroupRef);

      }
    }
    setUserActive(contact.customerActive == 1 ? true : false);
    changePanelPinned();
  }, [isPinned,contact.customerActive,contact.customerGroupRef,contact.erpCustomerCode]);



  const onPinClick = useCallback(() => {
    setIsPinned(!isPinned);
  }, [isPinned]);

  const onClosePanelClick = useCallback(() => {
    setIsPinned(false);
    changePanelOpened(false);

  }, []);

  const toggleEditHandler = useCallback(() => {
    setIsEditing(!isEditing);
  }, [isEditing]);

  const onSaveClick = useCallback(({ validationGroup } : ButtonTypes.ClickEvent) => {
    //customer group eklenirse session'daki listeyi de güncelle

  }, []);


  const navigateToDetails = useCallback(() => {

  }, []);


  const { t } = useTranslation();


  const handleOnChangeCG = (event) => {
    setCustomerGroupRef(event.value);
  };


  return (
    <div id='contact-panel' className={classNames({ 'panel': true, 'open': isOpened, 'pin': isPinned && (isLarge || isMedium) })}>
      <div className='data-wrapper'>
        <Toolbar className='panel-toolbar'>
          <ToolbarItem location='before'>
            <span className='contact-name value'>{contact.customerGroupName}</span>
          </ToolbarItem>

          <ToolbarItem
            location='after'
            visible={isLarge || isMedium}
          >
            <Button
              icon={isPinned ? 'pin' : 'unpin'}
              stylingMode='text'
              onClick={onPinClick}
            />
          </ToolbarItem>
          <ToolbarItem location='after'>
            <Button
              icon='close'
              stylingMode='text'
              onClick={onClosePanelClick}
            />
          </ToolbarItem>
        </Toolbar>
        <ScrollView className='panel-scroll'>

          <ValidationGroup>
            <div className='data-part border'>
              <Form
                className={classNames({ 'plain-styled-form': true, 'view-mode': !isEditing })}
              >
                <ToolbarItem location='before'>
            <CheckBox
              value={userActive}
              text={t("active")}
              onValueChanged={(e) => setUserActive(e.value)}
            />
          </ToolbarItem>

                <GroupItem colCount={2} cssClass='photo-row'>
                  <ColCountByScreen xs={2} />

                  <GroupItem>

                  </GroupItem>
                </GroupItem>

                <GroupItem cssClass='contact-fields-group'>

                <FormItem cssClass='accent'>
                      <FormTextbox
                        label="Customer Group Name"
                        value={contact.customerGroupName}
                        isEditing={!isEditing}
                        icon='card'
                        onValueChange={updateField('')}
                      />
                    </FormItem>

                <FormItem cssClass='accent'>
                      <FormTextbox
                        label="Customer Group Code"
                        value={contact.customerGroupCode}
                        isEditing={!isEditing}
                        icon='key'
                        onValueChange={updateField('')}
                      />
                    </FormItem>



                    <FormItem cssClass='accent'>
                      <FormTextbox
                        label="ERP Salesman Code"
                        value={contact["erpSalesManCode"]}
                        isEditing={!isEditing}
                        icon='card'
                        onValueChange={updateField('')}
                      />
                    </FormItem>

                    <FormItem cssClass='accent'>
                      <FormTextbox
                        label="ERP Warehouse"
                        value={contact["erpWarehouse"]}
                        isEditing={!isEditing}
                        icon='card'
                        onValueChange={updateField('')}
                      />
                    </FormItem>

                    <FormItem cssClass='accent'>
                      <FormTextbox
                        label="ERP Firm No"
                        value={contact["erpFirmNr"]}
                        isEditing={!isEditing}
                        icon='card'
                        onValueChange={updateField('')}
                      />
                    </FormItem>

                    <FormItem cssClass='accent'>
                      <FormTextbox
                        label="ERP Branch"
                        value={contact["erpBranch"]}
                        isEditing={!isEditing}
                        icon='card'
                        onValueChange={updateField('')}
                      />
                    </FormItem>

                </GroupItem>
              </Form>
            </div>

            <div className='data-part data-part-toolbar border'>
              <Toolbar>

                <ToolbarItem location='after' visible={true}>
                  <Button text={t("save")} icon='save' stylingMode='contained' type='default' onClick={onSaveClick} />
                </ToolbarItem>
              </Toolbar>
            </div>
          </ValidationGroup>

          <div className="list-container">

  </div>
        </ScrollView>
      </div>
    </div>
  );
};
