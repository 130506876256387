
import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckBox } from 'devextreme-react/check-box';
import classNames from 'classnames';
import { Button, ButtonTypes } from 'devextreme-react/button';
import { ScrollView } from 'devextreme-react/scroll-view';
import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';
import Form, { Item as FormItem, GroupItem, ColCountByScreen } from 'devextreme-react/form';
import { formatNumber } from 'devextreme/localization';
import { CustomerModel } from '../../../types/crm-contact';
import { CardActivities } from '../card-activities/CardActivities';
import { FormTextbox, FormPhoto, ContactStatus } from '../..';
import { useScreenSize } from '../../../utils/media-query';
import SelectBox from 'devextreme-react/select-box';
import ValidationGroup from 'devextreme-react/validation-group';

import List from 'devextreme-react/list';
import notify from 'devextreme/ui/notify';
import { customerUpdateRequest } from '../../../api/requests';
import { useTranslation } from 'react-i18next';
import { useCustomerContext } from '../../../contexts/customers';
const GroupTemplate = (item) => <div>Assigned: {item.key}</div>;

const renderCustomTitle = (item) => {
  return (
    <>
      <span>{item.title}</span>
    </>
  );
};



export const ContactPanelDetails = ({mainRefresh, contact, isOpened, changePanelOpened, onDataChanged, changePanelPinned } : {mainRefresh:() => void, contact: CustomerModel, isOpened: boolean, changePanelOpened:(value: boolean)=> void, onDataChanged:(data)=> void, changePanelPinned:() => void }) => {
  const [isPinned, setIsPinned] = useState(false);
  const [isEditing, setIsEditing] = useState(true);
  const { isLarge, isMedium } = useScreenSize();
  const { customers, setCustomers } = useCustomerContext();

  const [cGroups, setCGroups] = useState(JSON.parse(sessionStorage.getItem('customerGroups')??"[]"));
  const [customerGroupRef, setCustomerGroupRef] = useState(contact.customerGroupRef);

  const [userActive, setUserActive] = useState(contact.customerActive == 1 ? true : false);

  const navigate = useNavigate();

  const updateField = (field: string) => (value) => {
    onDataChanged({ ...contact, ...{ [field]: value } });
  };

  useEffect(() => {
    const storedData = sessionStorage.getItem('customerGroups');
    if (storedData) {
      var cg = JSON.parse(storedData);
      setCGroups(cg);

      if(contact.customerGroupRef == 0){
        setCustomerGroupRef(cg[0]["customerGroupRef"]);
console.log(customerGroupRef)
        const selectBox = document.getElementById('customerGroups') as HTMLSelectElement | null;
        if (selectBox) {
            selectBox.value = customerGroupRef.toString();
        }
      }else{
        setCustomerGroupRef(contact.customerGroupRef);

      }
    }
    setUserActive(contact.customerActive == 1 ? true : false);
    changePanelPinned();
  }, [isPinned,contact.customerActive,contact.customerGroupRef,contact.erpCustomerCode]);



  const onPinClick = useCallback(() => {
    setIsPinned(!isPinned);
  }, [isPinned]);

  const onClosePanelClick = useCallback(() => {
    setIsPinned(false);
    changePanelOpened(false);

  }, []);

  const toggleEditHandler = useCallback(() => {
    setIsEditing(!isEditing);
  }, [isEditing]);

  const onSaveClick = useCallback(({ validationGroup } : ButtonTypes.ClickEvent) => {
    // if (!validationGroup?.validate().isValid) return;

      customerUpdateRequest(
        contact.customerRef,
        userActive?1:0,
        contact.customerCode,
        contact.customerName,
        customerGroupRef,
        contact.erpCustomerRef,
        contact.erpShippingAdrRef,
        contact.productGroupRef
        ).then(function(res){
          if(res.resCode == 200){
            var her = customers;
            const targetIndex = customers.findIndex(customer => customer.erpCustomerCode === contact.erpCustomerCode );

            if(contact.customerRef == 0 ){

              contact.customerRef = res.dataSet[0]["customerRef"];
              her[targetIndex].customerRef = contact.customerRef;
            }
          contact.customerRef = res.dataSet[0]["customerRef"];

          her[targetIndex] = contact;
          her[targetIndex].customerActive = userActive ? 1 : 0;
          her[targetIndex].customerGroupRef = customerGroupRef;
          her[targetIndex]["customerGroupName"] = cGroups.filter(group => group["customerGroupRef"] == customerGroupRef)[0]["customerGroupName"];
          her[targetIndex].customerGroupCode = cGroups.filter(group => group["customerGroupRef"] == customerGroupRef)[0]["customerGroupCode"];

          setCustomers(her);

          changePanelOpened(false);

          notify("Güncelleme başarılı","success");
          }else{
            notify(res.resMessage,"error");
          }

        });


  }, [customers,userActive, customerGroupRef, contact.customerRef, contact.customerCode, contact.customerName]);


  const navigateToDetails = useCallback(() => {
    if(contact.customerRef == 0 ){
      notify("Müşteri kaydı oluşturuluyor, lütfen bekleyiniz","warning");
      customerUpdateRequest(
        contact.customerRef,
        userActive?1:0,
        contact.customerCode,
        contact.customerName,
        cGroups[0]["customerGroupRef"]??0,
        contact.erpCustomerRef,
        contact.erpShippingAdrRef,
        contact.productGroupRef
        ).then(function(res){
          if(res.resCode == 200){
          contact.customerRef = res.dataSet[0]["customerRef"];
          var her = customers;
          const targetIndex = customers.findIndex(customer => customer.erpCustomerCode === contact.erpCustomerCode );
          her[targetIndex] = contact;
          her[targetIndex].customerActive = userActive ? 1 : 0;
          her[targetIndex].customerRef = contact.customerRef;
          her[targetIndex].customerGroupRef = customerGroupRef;
          try{
            if(contact.customerGroupRef == 0){
              setCustomerGroupRef(cGroups[0]["customerGroupRef"]);}
            console.log(cGroups)
            console.log(customerGroupRef)
            her[targetIndex].customerGroupName = cGroups.filter(group => group["customerGroupRef"] == cGroups[0]["customerGroupRef"])[0]["customerGroupName"];
            her[targetIndex].customerGroupCode = cGroups.filter(group => group["customerGroupRef"] == cGroups[0]["customerGroupRef"])[0]["customerGroupCode"];

          }catch(e){
            console.log(e)
          }

          notify("Müşteri kaydı oluşturuldu","success");
          setCustomers(her);

          navigate('/b2b-customer-details', { state: { contact: contact } });
          }else{
            notify(res.resMessage,"error",3000);
          }

        });
    }else{
      navigate('/b2b-customer-details', { state: { contact: contact } });
    }

  }, [contact]);


  var updateCustomer = async () => {
    try {
    await customerUpdateRequest(
      contact.customerRef,
      userActive?1:0,
      contact.customerCode,
      contact.customerName,
      customerGroupRef,
      contact.erpCustomerRef,
      contact.erpShippingAdrRef,
      contact.productGroupRef
      ).then(function(res){
        if(res.resCode == 200){
          notify("Güncelleme başarılı!", "success", 1500);
        }else{
          notify(res.resMessage,"error",3000);
        }

      });



    } catch (error) {
      console.error('API hatası:', error);
    }
  };


  const { t } = useTranslation();

 const erpInfo = [{
  key: t("erpCustomerInfo"),
  items: [contact.erpCustomerCode, contact.erpCustomerName1, contact.erpCustomerAddr1, contact.erpCustomerPostCode+", "+ contact.erpCustomerCity],
}, {
  key: t("erpShippingInfo"),
  items: [contact.erpShippingAdrCode, contact.erpShippingAdrName1, contact.erpShippingAdrAddr1, contact.erpShippingAdrPostCode + ", "+contact.erpShippingAdrCity],
}];


  const handleOnChangeCG = (event) => {
    setCustomerGroupRef(event.value);
  };



  return (
    <div id='contact-panel' className={classNames({ 'panel': true, 'open': isOpened, 'pin': isPinned && (isLarge || isMedium) })}>
      <div className='data-wrapper'>
        <Toolbar className='panel-toolbar'>
          <ToolbarItem location='before'>
            <span className='contact-name value'>{contact.customerName}</span>
          </ToolbarItem>

          <ToolbarItem
            location='after'
            visible={isLarge || isMedium}
          >
            <Button
              icon={isPinned ? 'pin' : 'unpin'}
              stylingMode='text'
              onClick={onPinClick}
            />
          </ToolbarItem>
          <ToolbarItem location='after'>
            <Button
              icon='close'
              stylingMode='text'
              onClick={onClosePanelClick}
            />
          </ToolbarItem>
        </Toolbar>
        <ScrollView className='panel-scroll'>

          <ValidationGroup>
            <div className='data-part border'>
              <Form
                className={classNames({ 'plain-styled-form': true, 'view-mode': !isEditing })}
              >
                <ToolbarItem location='before'>
            <CheckBox
              value={userActive}
              text={t("active")}
              onValueChanged={(e) => setUserActive(e.value)}
            />
          </ToolbarItem>

                <GroupItem colCount={2} cssClass='photo-row'>
                  <ColCountByScreen xs={2} />

                  <GroupItem>

                  </GroupItem>
                </GroupItem>

                <GroupItem cssClass='contact-fields-group'>
                <FormItem cssClass='accent'>
                      <FormTextbox
                        label={t("customerCode")}
                        value={contact.customerCode}
                        isEditing={!isEditing}
                        icon='key'
                        onValueChange={updateField('customerCode')}
                      />
                    </FormItem>

                    <FormItem cssClass='accent'>
                      <FormTextbox
                        label={t("customerName")}
                        value={contact.customerName}
                        isEditing={!isEditing}
                        icon='card'
                        onValueChange={updateField('customerName')}
                      />
                    </FormItem>

                    <FormItem >
                      <p>{t("customerGroup")}</p>
                      <SelectBox
                       id="customerGroups"
                       value={customerGroupRef}
                       dataSource={cGroups}
                       displayExpr="customerGroupName"
                       valueExpr="customerGroupRef"
                       onValueChanged={handleOnChangeCG}
                       />
                    </FormItem>
                </GroupItem>
              </Form>
            </div>

            <div className='data-part data-part-toolbar border'>
              <Toolbar>
                <ToolbarItem location='after' visible={true}>
                 <Button text={t("detail")} icon='ordersbox' stylingMode='outlined' type='default' onClick={navigateToDetails}  />
                 </ToolbarItem>
                <ToolbarItem location='after' visible={true}>
                  <Button text={t("save")} icon='save' stylingMode='contained' type='default' onClick={onSaveClick} />
                </ToolbarItem>
              </Toolbar>
            </div>
          </ValidationGroup>

          <div className="list-container">
    <List
      dataSource={erpInfo}
      height="100%"
      grouped={true}
      collapsibleGroups={true}
       />
  </div>
        </ScrollView>
      </div>
    </div>
  );
};
