import axios from "axios";
import notify from "devextreme/ui/notify";
import { getCustomerGroupsRequest } from "./requests";
import { CustomerModel } from "../types/crm-contact";

export function setAppToken(token:string){
  sessionStorage.setItem("appToken",token)
}

export const getAppToken = ()=>{
  return sessionStorage.getItem("appToken");
}


export function setVendorId(vendorId:string){
  sessionStorage.setItem("vendorId",vendorId)
}

export const getVendorId = ()=>{
  return sessionStorage.getItem("vendorId");
}


export function setUserName(userName:string){
  sessionStorage.setItem("userName",userName)
}

export const getUserName = ()=>{
  return sessionStorage.getItem("userName")??"";
}


export const getCustomerGroups = async (token) => {
    const response = getCustomerGroupsRequest(token).then(function(res){
        if(res.data.resCode == 200){
            sessionStorage.setItem('customerGroups', JSON.stringify(res.data.dataSet));
        }else{
            notify("Customer Groups Error: "+res.data.resMessage,"error",3000)
        }
      });
}


export const customersToSession = (customers) => {
  sessionStorage.setItem("customers",customers);
}

export const customersFromSession = ()=>{
  return sessionStorage.getItem("customers")??"";
}


export const customerListFilterToSession = (filter:string) => {
  sessionStorage.setItem("customerFilter",filter);
}

export const customerListFilterFromSession = ()=>{
  return sessionStorage.getItem("customerFilter");
}


export const customerListSelectToSession = (select:string) => {
  sessionStorage.setItem("customerSelect",select);
}

export const customerListSelectFromSession = ()=>{
  return sessionStorage.getItem("customerSelect")??"";
}




 const customersUpdateSess = (code:number,customerM:CustomerModel)=>{
  // Önce session'dan mevcut listeyi alın
const existingListString = sessionStorage.getItem("customers")??"[]";

// JSON formatındaki string'i JavaScript nesnesine dönüştürün
const existingList = JSON.parse(existingListString);

// Güncellenecek öğenin customerCode değeri
const targetcustomerCode = code;

// Güncellenecek öğenin index'ini bulun
const targetIndex = existingList.findIndex(customer => customer.customerCode === targetcustomerCode);

if (targetIndex !== -1) {
    // Öğe bulunduysa güncellemeyi yapın
    existingList[targetIndex]= customerM;

    // Güncellenmiş listeyi JSON formatına dönüştürün
    const updatedListString = JSON.stringify(existingList);

    // Session'da güncellenmiş listeyi saklayın
    sessionStorage.setItem("customers", updatedListString);

    console.log("Liste başarıyla güncellendi!");
} else {
    console.log("Belirtilen customerCode değerine sahip öğe bulunamadı.");
}

}


